import dynamic from 'next/dynamic';
import { HomepageTabPanelProps } from './HomepageTabPanel.props';
import Typography from '@mui/material/Typography';
import { useColors } from '@hooks/useColors';
import Row from '@atoms/CustomGrid/Row';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import BackgroundText from '@atoms/BackgroundText/BackgroundText';
import theme from '@mui/theme';

const HomepageTabPanelCta = dynamic(
  () => import('@molecules/HomepageTabPanel/HomepageTabPanelCta/HomepageTabPanelCta')
);
const FeaturedProjects = dynamic(() => import('@molecules/HomepageTabPanel/FeaturedProjects/FeaturedProjects'));
const FeaturedProjectsTitle = dynamic(
  () => import('@molecules/HomepageTabPanel/FeaturedProjectsTitle/FeaturedProjectsTitle')
);
const LatestArticles = dynamic(() => import('@molecules/HomepageTabPanel/LatestArticles/LatestArticles'));

const HomepageTabPanel = (props: HomepageTabPanelProps) => {
  const { backgroundText, featuredItems, cta, description, colorVariant } = props;

  const { textColor } = useColors(colorVariant);

  return (
    <Box position="relative" minHeight={theme.spacing(500)}>
      <BackgroundText text={backgroundText} />

      <Row columns={10} sx={{ mt: { xs: 48, lg: 96 } }}>
        <Grid item lg={4} xs={10}>
          <Typography
            variant="bodyL"
            component="div"
            color={textColor}
            mb={32}
            dangerouslySetInnerHTML={{ __html: description }}
          />
          {cta && <HomepageTabPanelCta colorVariant={colorVariant} cta={cta} />}
        </Grid>

        {featuredItems && (
          <Grid item lg={6} xs={10}>
            <FeaturedProjectsTitle colorVariant={colorVariant} title={featuredItems.title} />

            {featuredItems.variant === 'project' && (
              <FeaturedProjects colorVariant={colorVariant} items={featuredItems.items} />
            )}
            {featuredItems.variant === 'article' && <LatestArticles items={featuredItems.items} />}
          </Grid>
        )}
      </Row>
    </Box>
  );
};

export default HomepageTabPanel;
