import Typography from '@mui/material/Typography';
import { BackgroundTextProps } from '@atoms/BackgroundText/BackgroundText.props';
import theme from '@mui/theme';
import styles from './BackgroundText.module.scss';
import { useMemo } from 'react';
import { SxProps, Theme } from '@mui/material';

const BackgroundText = ({ text, sxOverride, ...typographyProps }: BackgroundTextProps) => {
  const sx: SxProps<Theme> = useMemo(
    () => ({
      background: 'linear-gradient(180deg, #88A2AA -83.75%, rgba(136, 162, 170, 0) 110%)',
      width: '100%',
      backgroundClip: 'text',
      textFillColor: 'transparent',
      textAlign: 'center',
      position: 'absolute',
      opacity: 0.8,
      fontWeight: 100,
      fontSize: { xs: theme.spacing(54), lg: theme.spacing(110) },
      lineHeight: { xs: theme.spacing(80), lg: theme.spacing(80) },
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      paddingTop: { xs: 0, lg: theme.spacing(20) },
      top: { xs: theme.spacing(-50), lg: theme.spacing(-112) },
      ...(sxOverride || {}),
    }),
    [sxOverride]
  );

  return (
    <Typography variant="h3" sx={sx} className={styles.BackgroundText} {...typographyProps}>
      {text}
    </Typography>
  );
};

export default BackgroundText;
